import React, { useEffect, useMemo, useState } from "react";
import styles from "../../../src/styles/home.module.scss";
import TopDonorCard from "./topDonorCard";
import { observer } from "mobx-react";
import BlockContainer from "../mainPageBlocks/blockContainer";
import Spinner from "../spinner";
import CustomCarousel from "../ui/customCarousel";
import { useStores } from "../../hooks/mobx";
import TopDonorFilterBy from "../mainPage/TopDonorFilterBy/TopDonorFilterBy";

const TopDonor: React.FC = observer(() => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 3000, min: 1920 },
      items: 5,
      slidesToSlide: 5,
      partialVisibilityGutter: 40,
    },
    desktop: {
      breakpoint: { max: 1919, min: 1440 },
      items: 5,
      slidesToSlide: 5,
      partialVisibilityGutter: 20,
    },
    laptop: {
      breakpoint: { max: 1439, min: 1200 },
      items: 4,
      slidesToSlide: 4,
      partialVisibilityGutter: 20,
    },
    tablet: {
      breakpoint: { max: 1199, min: 768 },
      items: 2,
      slidesToSlide: 2,
      partialVisibilityGutter: 70,
    },
    mobile: {
      breakpoint: { max: 767, min: 360 },
      items: 1,
      slidesToSlide: 1,
      partialVisibilityGutter: 60,
    },
  };

  const [people, setPeople] = useState([]);
  const [loader, setLoader] = useState(true);
  const [filterBy, setFilterBy] = useState("");
  const [filterToTop, setFilterToTop] = useState(null);

  const { topDonorsStore, selectedCityStore } = useStores();

  useEffect(() => {
    setLoader(true);
    topDonorsStore
      .loads({ city_id: selectedCityStore.cityID })
      .then((response) => {
        setPeople(response.items);
        setFilterBy(response.filter_by);
        setFilterToTop(response.see_all);
        setLoader(false);
      })
      .catch(() => {
        setLoader(true);
      });
  }, []);

  const blockTitle = useMemo(() => {
    if (filterToTop?.by_year) {
      return "ТОП доноров за год";
    } else {
      return "ТОП доноров за всё время";
    }
  }, [filterToTop]);

  const filterByType = useMemo(() => {
    if (filterToTop?.city) {
      return "city";
    }
    if (filterToTop?.blood_station_id) {
      return "blood_station_id";
    }
    if (filterToTop?.country) {
      return "country";
    }
    if (filterToTop?.region) {
      return "region";
    }
  }, [filterToTop]);

  if (loader)
    return (
      <div className={`container`}>
        <Spinner toggled={true} background={true} />
      </div>
    );

  if (!people.length) return null;

  return (
    <BlockContainer
      isGrid
      isBonus
      isSSR={true}
      title={blockTitle}
      linkText={"Смотреть всех"}
      linkUrl={`/top/?${new URLSearchParams(filterToTop).toString()}`}
    >
      <>
        {filterByType?.length > 0 && (
          <TopDonorFilterBy by={filterByType} text={filterBy} />
        )}
        {people && (
          <CustomCarousel
            removeArrowOnDeviceType={["mobile"]}
            removeDotsOnDeviceType={["mobile"]}
            swipeable={true}
            draggable={false}
            minimumTouchDrag={70}
            responsive={responsive}
            partialVisible={true}
            infinite={true}
          >
            {people.map((info) => (
              <div className={styles.swiper_customElement} key={info.id}>
                <TopDonorCard info={info} />
              </div>
            ))}
          </CustomCarousel>
        )}
      </>
    </BlockContainer>
  );
});

export default TopDonor;
