import React, {Component} from 'react';
import styles from './bonusCard.module.scss';
import {inject, observer} from "mobx-react";
import {BONUS_CARD_STATUSES} from "../../../entities/bonus_card";
import UiStore from "../../../stores/UiStore";
import {BonusCardModel} from "./model";
import Link from 'next/link';
import {sendMetrics} from "../../../utils/misc";


@inject('uiStore')
@observer
export default class BonusCard extends Component<any, BonusCardModel> {
  props: {
    info: BonusCardModel;
    showStatus?: boolean;
    uiStore?: UiStore;
    showDesktopOnMobile?: boolean;
    metricsOnClick?: string;
  };

  render() {
    const {info, showStatus = true, showDesktopOnMobile = false, metricsOnClick} = this.props;
    return (
      <Link href={`/bonus/${info.id}/`}>
        <div className={showDesktopOnMobile ? styles.BonusCardOnMobile : styles.BonusCard}
             onClick={metricsOnClick ? () => sendMetrics(metricsOnClick) : null}
        >
          <div className={styles.BonusCardTitle}
               style={{ backgroundImage: `linear-gradient(0deg, rgba(193,196,202,0) 0%, rgba(95,97,119,1) 100%), url(${info.bonusImage})` }}>
            <span>{info.partnerName}</span>
          </div>
          <div className={styles.BonusCardContent}>
            <div className={styles.BonusCardContentTitle}>
              <span>{info.bonusName}</span>
            </div>
            <div className={styles.BonusCardContentInfo}>
              <div className={styles.BonusCardContentInfoLeft}>
                {showStatus && info.status && <div className={styles.BonusCardContentInfoLeftStatus}>
                    <img src={BONUS_CARD_STATUSES[info.status].icon} alt={info.status}/>
                    <span>{BONUS_CARD_STATUSES[info.status].label}</span>
                </div>}
                <span className={styles.BonusCardContentInfoLeftDate}>{info.dateValidityBeautify}</span>
              </div>
              <div className={styles.BonusCardContentInfoRight}>
                <div className={styles.BonusCardContentInfoRightImage}
                     style={{ backgroundImage: `url(${info.partnerImage})` }}
                />
              </div>
            </div>
          </div>
        </div>
      </Link>
    )
  }
}
