import React from "react";
import Link from "next/link";
import styles from "./BannerNoLog.module.scss";
import { sendMetrics } from "../../utils/misc";

const BannerNoLog = () => {
  return (
    <div className={styles.head_NoLogWrapper}>
      <div className={styles.head_NoLog}>
        <h1 className={styles.head_NoLog_title}>
          <span>DonorSearch — </span>
          твой путеводитель в мире донорства
        </h1>
        <div className={styles.head_NoLog_subtitleWrap}>
          <p>
            Донорство крови — это приключение.
            <br />
            Решайся, а в остальном мы поможем
          </p>
        </div>
        <Link
          href={
            "/login/?utm_source=main_page&utm_medium=add_button&utm_campaign=donor_search&next=/&tag=MainPage"
          }
        >
          <a
            className={styles.head_NoLog_btn}
            onClick={() => sendMetrics("joinUs")}
          >
            Присоединиться
          </a>
        </Link>
      </div>
    </div>
  );
};

export default BannerNoLog;
