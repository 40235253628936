import React, { useEffect, useRef } from "react";
import { useObserver } from "mobx-react";

import BlockContainer from "../blockContainer";
import { useStore } from "../../../hooks/mobx";
import Spinner from "../../spinner";
import CustomCarousel from "../../ui/customCarousel";
import TopDonorCard from "../../topDonor/topDonorCard";

import styles from "./DonatorAdvices.module.scss";
import DonatorAdvicesModal from "./DonatorAdvicesModal";
import redirectTo from "../../../utils/redirectTo";

function useDontaorAdvicesStore() {
  const { donatorAdvicesStore, authStore } = useStore([
    "donatorAdvicesStore",
    "authStore",
  ]);
  return useObserver(() => ({
    donatorAdvices: donatorAdvicesStore.donatorAdvices,
    can_add: donatorAdvicesStore.donatorModalData?.can_add,
    afterRedirect: donatorAdvicesStore.afterRedirect,
    toggleDonatorAdviceModal: donatorAdvicesStore.toggleDonatorAdviceModal,
    user: authStore.user,
    donatorAdvicesStore,
    authStore,
  }));
}

const DonatorAdvices = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 3000, min: 1920 },
      items: 3,
      slidesToSlide: 3,
    },
    desktop: {
      breakpoint: { max: 1919, min: 1440 },
      items: 2,
      slidesToSlide: 2,
      partialVisibilityGutter: 150,
    },
    laptop: {
      breakpoint: { max: 1439, min: 1200 },
      items: 2,
      slidesToSlide: 2,
      partialVisibilityGutter: 150,
    },
    tablet: {
      breakpoint: { max: 1200, min: 1000 },
      items: 2,
      slidesToSlide: 2,
      partialVisibilityGutter: 70,
    },
    mini_tablet: {
      breakpoint: { max: 1000, min: 768 },
      items: 2,
      slidesToSlide: 2,
      partialVisibilityGutter: 50,
    },
    mobile: {
      breakpoint: { max: 767, min: 360 },
      items: 1,
      slidesToSlide: 1,
      partialVisibilityGutter: 40,
    },
  };
  const {
    toggleDonatorAdviceModal,
    donatorAdvicesStore,
    donatorAdvices,
    afterRedirect,
    can_add,
    user,
  } = useDontaorAdvicesStore();

  const afterRedirectRef = useRef(null);

  useEffect(() => {
    donatorAdvicesStore.load();
  }, []);

  useEffect(() => {
    if (afterRedirect) {
      afterRedirectRef.current?.scrollIntoView();
      donatorAdvicesStore.setAfterRedirect(false);
    }
  }, [afterRedirectRef]);

  const onClick = () => {
    if (user) {
      toggleDonatorAdviceModal(true);
    } else {
      donatorAdvicesStore.setAfterRedirect(true);
      redirectTo("/login/?next=/&tag=Advice");
    }
  };

  if (!donatorAdvices)
    return (
      <div className={"w-100 mb-5"} style={{ height: "400px" }}>
        <Spinner toggled={true} background={true} />
      </div>
    );

  return (
    <div ref={afterRedirectRef}>
      <DonatorAdvicesModal />
      <BlockContainer
        title={"Советы доноров"}
        linkText={"Хочу добавить совет"}
        disabled={!user ? false : !can_add || !user}
        onCLick={onClick}
        tooltipCondition={!can_add && !!user}
        tooltip={"Вы уже оставили совет"}
      >
        <CustomCarousel
          responsive={responsive}
          removeArrowOnDeviceType={["mobile"]}
          removeDotsOnDeviceType={["mobile"]}
          swipeable={true}
          draggable={true}
          infinite={true}
          partialVisible={true}
        >
          {donatorAdvices.map((advice) => {
            return (
              <div className={styles.DonatorAdvices} key={advice?.text}>
                <TopDonorCard info={advice} type={"with_advice"} />
              </div>
            );
          })}
        </CustomCarousel>
      </BlockContainer>
    </div>
  );
};

export default DonatorAdvices;
