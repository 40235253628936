import React, { useMemo } from "react";
import styles from "./SocialBlock.module.scss";
import { sendMetrics, sliceNumbers } from "../../../utils/misc";
import Link from "next/link";

type TSocialBlock = {
  vk_participant: number;
  inst_participant: number;
};

type TLocalSocial = {
  count: number;
  type: "vk" | "inst" | "tg";
};

const SocialBlock: React.FC<TSocialBlock> = ({
  vk_participant,
  inst_participant,
}) => {
  return (
    <div className={styles.SocialBlock}>
      <div className={styles.SocialBlock_Left}>
        <Social count={vk_participant} type={"vk"} />
        <Social count={inst_participant} type={"tg"} />
      </div>
      <div className={styles.SocialBlock_Right}>
        <FirstTimeHere />
      </div>
    </div>
  );
};

const FirstTimeHere: React.FC = () => {
  return (
    <Link
      href={
        "/how/?utm_source=main_page&utm_medium=how&utm_campaign=donor_search"
      }
    >
      <div className={styles.FirstTimeHere}>
        <div className={styles.FirstTimeHere_Title}>
          <span>Сдаешь в первый раз?</span>
        </div>
        <div className={styles.FirstTimeHere_Lead}>
          <span>Мы подготовили гайд, который ответит на все вопросы</span>
        </div>
        <div className={styles.FirstTimeHere_Action}>
          <span>Ознакомиться</span>
        </div>
        <img
          className={styles.FirstTimeHere_Image}
          src="/static/images/line-social.png"
          alt="Lines"
        />
      </div>
    </Link>
  );
};

const Social: React.FC<TLocalSocial> = ({ count, type }) => {
  const link = useMemo(() => {
    switch (type) {
      case "vk":
        return "https://vk.com/donorsearch";
      case "inst":
        return "https://www.instagram.com/donorsearch/";
      case "tg":
        return "https://t.me/donorsearch";
    }
  }, [type]);

  const text = useMemo(() => {
    switch (type) {
      case "vk":
        return "Наше сообщество ВКонтакте";
      case "inst":
        return "Наша жизнь в Instagram";
      case "tg":
        return "Наша жизнь в Telegram";
    }
  }, [type]);

  const onPressBlock = () => {
    switch (type) {
      case "vk":
        sendMetrics("to_vk_from_main_page");
        break;
      case "inst":
        sendMetrics("to_instagram_from_main_page");
        break;
      case "tg":
        sendMetrics("to_telegram_from_main_page");
        break;
    }
  };

  if (!count) return null;

  return (
    <a
      className={styles.Social}
      target={"_blank"}
      href={link}
      onClick={onPressBlock}
      data-type={type}
    >
      <div className={styles.Social_Title}>
        <span>{text}</span>
      </div>
      <div className={styles.Social_Counter}>
        <span>{sliceNumbers(count)} участников</span>
      </div>
      <img
        className={styles.Social_Icon}
        src={`/static/images/${type}.svg`}
        alt="Icon"
      />
      <img
        className={styles.Social_Lines}
        src={`/static/images/line-${type}.svg`}
        alt="Lines"
      />
    </a>
  );
};

export default SocialBlock;
