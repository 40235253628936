import React, { useEffect, useMemo, useState } from "react";
import BlockContainer from "../blockContainer";
import { observer } from "mobx-react";
import MainBannerCard from "../../mainBannerCard";
import styles from "./bannerBlock.module.scss";
import Spinner from "../../spinner";
import BannerNoLog from "../../bannerNoLog";
import CustomCarousel from "../../ui/customCarousel";
import { useStores } from "../../../hooks/mobx";

const BannersBlock: React.FC = observer(() => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 3000, min: 1920 },
      items: 1,
      slidesToSlide: 1,
    },
    desktop: {
      breakpoint: { max: 1919, min: 1440 },
      items: 1,
      slidesToSlide: 1,
    },
    laptop: {
      breakpoint: { max: 1439, min: 1200 },
      items: 1,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1199, min: 768 },
      items: 1,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 767, min: 360 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  const { authStore, bannersStore } = useStores();
  const [banners, setBanners] = useState([]);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    bannersStore
      .loads()
      .then((response) => {
        setBanners(response);
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
  }, []);

  const content = useMemo(() => {
    const preContent = [];
    preContent.push(
      banners.map((info, i) => <MainBannerCard key={i} info={info} />)
    );

    if (!authStore?.user)
      preContent.unshift(<BannerNoLog key={"likeDefault"} />);

    preContent.push(<MainBannerCard isDefault={true} key={"default"} />);

    return preContent;
  }, [authStore?.user, banners]);

  if (loader)
    return (
      <div className={`${styles.bannerSpinner} container`}>
        <Spinner toggled={true} background={true} />
      </div>
    );

  // if (!banners.length) return null;

  return (
    <BlockContainer isLink={false} isSSR={false} title={null} isBanner={true}>
      <CustomCarousel
        removeArrowOnDeviceType={["mobile"]}
        swipeable={true}
        draggable={true}
        responsive={responsive}
        minimumTouchDrag={70}
        infinite
        partialVisible={false}
        autoPlay={true} 
      >
        {content}
      </CustomCarousel>
    </BlockContainer>
  );
});

export default BannersBlock;
