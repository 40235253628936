import {BonusCardStatus} from "./bonus_card_common";

export type BonusCardStatuses =
  | 'accepted';
  // | 'notAccepted';

export type BonusCardStatusType = {
  [s in BonusCardStatuses]: BonusCardStatus;
}

export const BONUS_CARD_STATUSES: BonusCardStatusType = {
  accepted: {label: 'Бонус взят', icon: '/static/images/icons/bonusCardAccept.svg'},
};
