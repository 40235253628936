import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { BonusCardModel } from "../../bonusPage/bonusCard/model";
import styles from "../../../styles/home.module.scss";
import BonusCard from "../../bonusPage/bonusCard/bonusCard";
import BlockContainer from "../blockContainer";
import scss from "../bannersBlock/bannerBlock.module.scss";
import Spinner from "../../spinner";
import CustomCarousel from "../../ui/customCarousel";
import { useStores } from "../../../hooks/mobx";

const BonusesBlock: React.FC = observer(() => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1920 },
      items: 6,
      slidesToSlide: 6,
      partialVisibilityGutter: -0.17,
    },
    desktop: {
      breakpoint: { max: 1919, min: 1440 },
      items: 5,
      slidesToSlide: 5,
      partialVisibilityGutter: -0.5,
    },
    laptop: {
      breakpoint: { max: 1439, min: 1200 },
      items: 4,
      slidesToSlide: 4,
      partialVisibilityGutter: -0.3,
    },
    tablet: {
      breakpoint: { max: 1199, min: 768 },
      items: 2,
      slidesToSlide: 2,
      partialVisibilityGutter: -0.55,
    },
    mobile: {
      breakpoint: { max: 767, min: 360 },
      items: 1,
      slidesToSlide: 1,
      partialVisibilityGutter: 0,
    },
  };
  const [bonuses, setBonuses] = useState([]);
  const [loader, setLoader] = useState(true);

  const { bonusStore, selectedCityStore } = useStores();

  useEffect(() => {
    const city_id = selectedCityStore.cityID;
    setLoader(true);
    bonusStore
      .loadBonusesByUrl(
        city_id
          ? `api/bonuses/main_page_bonuses/?city_id=${city_id}`
          : "api/bonuses/main_page_bonuses/"
      )
      .then((response) => {
        setBonuses(response.map((res) => new BonusCardModel(res)));
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
  }, []);

  if (loader) {
    return (
      <div className={`${scss.bonusBlock} container`}>
        <Spinner toggled={true} background={true} />
      </div>
    );
  }

  if (!bonuses.length) return null;

  return (
    <BlockContainer
      linkUrl={"/bonus/"}
      isGrid
      isBonus
      title={"Бонусы для доноров"}
      withMetric={"from_main_page_to_all_bonus"}
      linkText={"Все бонусы"}
    >
      <CustomCarousel
        removeArrowOnDeviceType={["mobile"]}
        swipeable={true}
        draggable={false}
        minimumTouchDrag={70}
        responsive={responsive}
        infinite
        partialVisible={true}
        containerStyle={{ marginLeft: "1px" }}
      >
        {bonuses.map((bonus) => {
          return (
            <div key={bonus.id} className={styles.Card_Bonus}>
              <BonusCard
                showDesktopOnMobile
                showStatus
                info={bonus}
                metricsOnClick={"to_bonus_from_main_page"}
              />
            </div>
          );
        })}
      </CustomCarousel>
    </BlockContainer>
  );
});

export default BonusesBlock;
