import React, {Component} from 'react';
import styles from "../mainNewsCard/mainNewsCard.module.scss";
import {IInterestingBlock} from "../../entities/interestingBlock_common";


class MainNewsCard extends Component<any, IInterestingBlock> {
  render() {
    return (
      <a
          style={{gridColumn: '1 / 4'}}
        href={this.props.info.data.url + '?utm_source=main_page&utm_medium=interesting_for_you&utm_campaign=donor_search'}
        target={"_blank"}>
        <div className={styles.mainNewsCard}>
          <div className={styles.mainNewsCard_info}>
            <span className={styles.mainNewsCard_info_title}>{this.props.info.data.name}</span>
            <span className={styles.mainNewsCard_info_time}>{this.props.info.data.read_time} чтения</span>
          </div>
          <div className={styles.mainNewsCard_img} style={{backgroundImage: `url('${this.props.info.data.image}')`}}>
          </div>
        </div>
      </a>
    )
  }
}

export default MainNewsCard;
