import React, { useMemo } from "react";
import styles from "./TopDonorFilterBy.module.scss";

type TTopDonorFilterBy = {
  text: string;
  by: "city" | "blood_station_id" | "country" | "region";
};

const TopDonorFilterBy: React.FC<TTopDonorFilterBy> = ({ text, by }) => {
  const filter = useMemo(() => {
    switch (by) {
      case "blood_station_id":
        return "По центру крови";
      case "city":
        return "По городу";
      case "country":
        return "По стране";
      case "region":
        return "По региону";
    }
  }, [by]);

  const filterBy = useMemo(() => {
    switch (by) {
      case "blood_station_id":
        return text.replace("По центру крови", "");
      case "city":
        return text.replace("По городу", "");
      case "country":
        return text.replace("По стране", "");
      case "region":
        return text.replace("По региону", "");
    }
  }, [text, by]);

  if (!text.length) return null;

  return (
    <div className={styles.TopDonorFilterByContainer}>
      <div className={styles.TopDonorFilterBy}>
        <div className={styles.TopDonorFilterBy_Type}>{filter}</div>
        <div className={styles.TopDonorFilterBy_Text}>{filterBy}</div>
      </div>
    </div>
  );
};

export default TopDonorFilterBy;
