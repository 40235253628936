import React, { useEffect, useState } from "react";
import { observer, useObserver } from "mobx-react";
import BlockContainer from "../blockContainer";
import styles from "../../../styles/home.module.scss";
import MainEventCard from "../../mainEventCard";
import CustomCarousel from "../../ui/customCarousel";
import { useStore } from "../../../hooks/mobx";
import Spinner from "../../spinner";

function useStores() {
  const { selectedCityStore, uiStore, eventStore } = useStore([
    "selectedCityStore",
    "uiStore",
    "eventStore",
  ]);
  return useObserver(() => ({
    getEventsForMainPage: eventStore.getEventsForMainPage,
    getCityId: selectedCityStore.cityID,
    selectedCityStore,
    eventStore,
    uiStore,
  }));
}

const EventsBlock = observer(({currentUser}) => {
  const { getEventsForMainPage, getCityId } = useStores();
  const [eventsList, setEventsList] = useState([]);
  const [loading, setLoading] = useState(true);

  const responsiveBanner = {
    superLargeDesktop: {
      breakpoint: { max: 3000, min: 1920 },
      slidesToSlide: 4,
      items: 4,
    },
    desktop: {
      breakpoint: { max: 1919, min: 1400 },
      items: 4,
      slidesToSlide: 4,
    },
    tablet: {
      breakpoint: { max: 1399, min: 1200 },
      items: 3,
      slidesToSlide: 3,
    },
    smallTablet: {
      breakpoint: { max: 1199, min: 768 },
      items: 2,
      slidesToSlide: 2,
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  useEffect(() => {
    setLoading(true);
    getEventsForMainPage(getCityId || "").then((res) => {
      setEventsList(res);
      setLoading(false);
    });
  }, []);

  if (loading)
    return (
      <div className={`container`}>
        <Spinner toggled={true} background={true} />
      </div>
    );

  if (!eventsList?.length) return null;

  return (
    <BlockContainer
      title={"Мероприятия"}
      isSSR={false}
      isGrid
      isBonus
      linkText={"Все мероприятия"}
      isLink={false}
      linkUrl={`${process.env.NEXT_PUBLIC_EVENTS_URL}events/?active_tab=future&search=&utm_source=main_page&utm_medium=events&utm_campaign=donor_search`}
    >
      <CustomCarousel
        removeArrowOnDeviceType={["mobile"]}
        responsive={responsiveBanner}
        minimumTouchDrag={70}
        infinite={true}
        arrows={true}
        dots={true}
        swipeable={true}
        draggable={false}
        partialVisible={true}
      >
        {eventsList.map((event) => {
          return (
            <div key={event.id} className={styles.Card_Event}>
              <MainEventCard event={event} currentUser={currentUser} />
            </div>
          );
        })}
      </CustomCarousel>
    </BlockContainer>
  );
});

export default EventsBlock;
