import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import MainNewsCard from "../../mainNewsCard";
import BlockContainer from "../blockContainer";
import styles from "../../../styles/home.module.scss";
import scss from "../bannersBlock/bannerBlock.module.scss";
import Spinner from "../../spinner";
import CustomCarousel from "../../ui/customCarousel";
import { useStores } from "../../../hooks/mobx";

const InterestingForYou: React.FC = observer(() => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1920 },
      items: 5,
      slidesToSlide: 5,
    },
    desktop: {
      breakpoint: { max: 1919, min: 1440 },
      items: 3,
      slidesToSlide: 3,
    },
    laptop: {
      breakpoint: { max: 1439, min: 1200 },
      items: 3,
      slidesToSlide: 3,
    },
    tablet: {
      breakpoint: { max: 1199, min: 768 },
      items: 2,
      slidesToSlide: 2,
    },
    mobile: {
      breakpoint: { max: 767, min: 360 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  const { interestingBlockStore, selectedCityStore } = useStores();

  const [interestingList, setInterestingList] = useState([]);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    const filter = {
      msk: selectedCityStore.isMoscow,
      city_id: selectedCityStore.cityID,
    };

    interestingBlockStore
      .load(9, 1, filter)
      .then((response) => {
        setInterestingList(response.records);
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
  }, []);

  if (loader)
    return (
      <div className={`${scss.interestingBlock} container`}>
        <Spinner toggled={true} background={true} />
      </div>
    );

  if (!interestingList.length) return null;

  return (
    <BlockContainer
      isBonus
      linkText={"Смотреть все"}
      isSSR={false}
      isGrid
      title={"Интересное для вас"}
      linkUrl={
        "https://journal.donorsearch.org/?utm_source=main_page&utm_medium=interesting_for_you&utm_campaign=donor_search"
      }
    >
      <CustomCarousel
        removeArrowOnDeviceType={["mobile"]}
        swipeable={true}
        draggable={false}
        responsive={responsive}
        minimumTouchDrag={70}
        infinite
        partialVisible={false}
        arrows={true}
      >
        {interestingList.map((interesting) => {
          return (
            <div key={interesting.id} className={styles.swiper_element}>
              <div className={styles.mainDonationCards}>
                <MainNewsCard key={interesting.id} info={interesting} />
              </div>
            </div>
          );
        })}
      </CustomCarousel>
    </BlockContainer>
  );
});

export default InterestingForYou;
