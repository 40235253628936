import React from "react";
import { Modal } from "semantic-ui-react";
import { useObserver } from "mobx-react";

import styles from "./DonatorAdvices.module.scss";
import { useStore } from "../../../hooks/mobx";

function useDontaorAdvicesStoreModal() {
  const { donatorAdvicesStore, uiStore } = useStore([
    "donatorAdvicesStore",
    "uiStore",
  ]);
  return useObserver(() => ({
    setDonatorModalDataText: donatorAdvicesStore.setDonatorModalDataText,
    can_add: donatorAdvicesStore?.donatorModalData?.can_add,
    is_open: donatorAdvicesStore?.donatorModalData?.is_open,
    text: donatorAdvicesStore?.donatorModalData?.text,
    sendDonatorAdvice: donatorAdvicesStore.sendDonatorAdvice,
    loading: donatorAdvicesStore.loading,
    isDone: donatorAdvicesStore.isDone,
    toggleDonatorAdviceModal: donatorAdvicesStore.toggleDonatorAdviceModal,
    buttonStatus: donatorAdvicesStore.buttonStatus,
    isMobile: uiStore.isMobile,
    donatorAdvicesStore,
  }));
}

const DonatorAdvicesModal = () => {
  const MAX_LENGTH = 400;

  const {
    isMobile,
    toggleDonatorAdviceModal,
    can_add,
    is_open,
    text,
    buttonStatus,
    setDonatorModalDataText,
    sendDonatorAdvice,
    loading,
    isDone,
  } = useDontaorAdvicesStoreModal();
  return (
    <Modal
      open={is_open && can_add}
      className={styles.DonatorAdvicesModal}
      onClose={() => {
        toggleDonatorAdviceModal(false);
      }}
    >
      {isMobile && (
        <div className={styles.DonatorAdvicesModal_Header}>
          <span className={styles.DonatorAdvicesModal_Header_Text}>
            Совет донорам
          </span>
          <img
            className={styles.DonatorAdvicesModal_Header_Image}
            onClick={() => {
              toggleDonatorAdviceModal(false);
            }}
            src="/static/images/icons/close.svg"
            alt=""
          />
        </div>
      )}
      <div className={styles.DonatorAdvicesModal_Content}>
        <img
          className={styles.DonatorAdvicesModal_Content_Image}
          onClick={() => {
            toggleDonatorAdviceModal(false);
          }}
          src="/static/images/icons/close.svg"
          alt=""
        />
        {isDone && (
          <div
            className={styles.DonatorAdvicesModal_Content_Done}
            onClick={() => {
              toggleDonatorAdviceModal(false);
            }}
          >
            <img
              className={styles.DonatorAdvicesModal_Content_Done_Image}
              src="/static/images/mainLogo.png"
              alt=""
            />
            <div className={styles.DonatorAdvicesModal_Content_Done_Text}>
              <div
                className={styles.DonatorAdvicesModal_Content_Done_Text_Header}
              >
                Спасибо за ваш совет!
              </div>
              <div
                className={styles.DonatorAdvicesModal_Content_Done_Text_Footer}
              >
                Совет отправлен на модерацию и после успешного прохождения
                модерации будет отображен в Советах донорам.
              </div>
            </div>
          </div>
        )}
        {!isDone && (
          <>
            <div className={styles.DonatorAdvicesModal_Content_Data}>
              <div className={styles.DonatorAdvicesModal_Content_Data_Header}>
                Оставьте свой совет другим донорам
              </div>
              <div className={styles.DonatorAdvicesModal_Content_Data_Info}>
                Доступно донорам, сделавшим 5 и более донаций
              </div>
              <textarea
                maxLength={MAX_LENGTH}
                rows={isMobile ? 10 : 5}
                placeholder={"Начните делиться опытом"}
                className={styles.DonatorAdvicesModal_Content_Data_TextArea}
                onChange={(e) => {
                  setDonatorModalDataText(e.target.value);
                }}
                value={text}
              />
              <div className={styles.DonatorAdvicesModal_Content_Data_Counter}>
                {text?.length} / {MAX_LENGTH}
              </div>
            </div>
            <div className={styles.DonatorAdvicesModal_Content_Button}>
              {loading ? (
                <div
                  className={styles.DonatorAdvicesModal_Content_Button_Loading}
                >
                  <img
                    className={"preloader"}
                    src="/static/images/icons/preloader.svg"
                    alt={""}
                  />
                </div>
              ) : (
                <button
                  onClick={sendDonatorAdvice}
                  className={
                    buttonStatus
                      ? styles.DonatorAdvicesModal_Content_Button_Active
                      : styles.DonatorAdvicesModal_Content_Button_Disable
                  }
                >
                  Отправить
                </button>
              )}
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default DonatorAdvicesModal;
